<template>
  <v-card height="100%">
    <block class="loading-deep" v-if="loading"></block>
    <div class="background-card px-5 py-5 " style="height: 100%;overflow-y: scroll;">
      <div class="d-flex mb-4 justify-space-between align-center" >
        <span v-if="this.data">Modificar servicio seleccionado</span>
        <span v-else><v-icon>mdi-cog</v-icon> Agregar nuevo servicio</span>
        <v-btn text @click="$emit('close')" class="close-icon">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-form v-model="valid">

        <div class="">
          <v-row v-if="typebusiness == 'veterinary'">
            <v-col cols="6" md="3">
              <v-checkbox   :readonly="form.TypeService =='PLT'" v-model="isVaccine" label="Es Vacuna"></v-checkbox>
            </v-col>
            <v-col cols="6" md="3">
              <v-checkbox :readonly="form.TypeService =='PLT'"  v-model="isMedicine" label="Es Medicamento"></v-checkbox>
            </v-col>
            <v-col cols="6" md="3">
              <v-checkbox v-model="isHospital" label="Es Hospital"></v-checkbox>
            </v-col>
            <v-col cols="6" md="3">
              <v-checkbox v-model="isHost" label="Es Hospedaje"></v-checkbox>
            </v-col>
          </v-row>
          <v-row v-if="typebusiness == 'medicalclinic'">
            <v-col cols="6" md="3">
              <v-checkbox v-model="isHospital" label="Es Hospital"></v-checkbox>
            </v-col>
            <!-- <v-col cols="6" md="3">
              <v-checkbox v-model="isHost" label="Es Hospedaje"></v-checkbox>
            </v-col> -->
          </v-row>

          <v-row>
            <v-col cols="12" md="6"><v-text-field outlined dense v-model="form.NameService" label="Nombre del servicio"></v-text-field></v-col>
            <v-col cols="12" md="6"><v-text-field outlined dense v-model="form.Name" label="Nombre a facturar"></v-text-field></v-col>
            <v-col cols="12" md="4">
              <v-text-field outlined dense v-model="form.Price" label="Precio con IVA" type="number"></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="mb-3">
              <v-btn color="comment" dark @click="isIva=!isIva">{{!isIva ? 'Agregar IVA' : 'Restar IVA'}}</v-btn>
            </v-col>
          </v-row>

          <v-card class="my-3 ml-1 px-2 py-1">
            <v-row class="mt-3 pb-4">
              <v-col cols="12" md="6">
                <v-autocomplete outlined dense label="Familia" v-model="form.family" :items="families" item-text="name" attach
                item-value="id"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete outlined dense label="Categoría" v-model="form.category" :items="filteredCategories"
                item-text="name" attach item-value="id" :disabled="!form.family"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete outlined dense label="Subcategoría" v-model="form.subcategory" :items="filteredSubcategories"
                attach item-text="name" item-value="id" :disabled="!form.category"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="mb-2"> <v-btn  color="primary" @click="dialog = true">Gestionar segmentación</v-btn></v-col>
            </v-row>
          </v-card>

          <!--v-text-field v-model="DefaultTime" type="number" v-if="isVaccine || isMedicine" label="Periodo de tiempo"></v-text-field-->

        <template v-if="isVaccine || isMedicine">
          <v-row class="d-flex flex-row">
            <v-col cols="9" md="5">
              <v-autocomplete outlined dense v-model="form.customTime" attach :items="periodTime" item-text="time"
                item-value="time" label="Periodo de puesta"></v-autocomplete>
            </v-col>
            <v-col cols="1" md="1" class="d-flex align-start">
              <v-btn @click="openAddPeriods = !openAddPeriods" class="btn-add"><v-icon>mdi-calendar-edit</v-icon></v-btn>
            </v-col>
            <v-col cols="12" md="6"> <v-btn class="btncode width-100 mb-6" @click="dialog = true">Gestionar segmentación</v-btn></v-col>
          </v-row>
          
          <v-row class="d-flex flex-row">
            <v-col cols="12" md="6">
              <v-select outlined dense attach v-if="(isVaccine || isMedicine) && typebusiness == 'veterinary'"
              v-model="form.species" :items="typeItem" label="Especies:"></v-select>
            </v-col>
          </v-row>
          <v-dialog v-model="openAddPeriods" width="450">
            <v-card>
              <v-card-title>Agregar periodos de tiempo</v-card-title>
              <v-card-text>
                <v-row>
                  <!--v-col cols="12">
                    <v-select outlined dense  attach :items="periodTime" item-text="time" item-value="time" label="Periodos de tiempo del sistema"></v-select>
                  </v-col-->
                  <v-col cols="12">
                    <v-text-field outlined dense v-model="periodform.ParameterName" type="number"
                      label="Nuevo período de tiempo"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select outlined dense attach v-model="periodform.ParameterValue"
                      :items="['Día', 'Semana', 'Mes', 'Año']" label="Unidad del nuevo período"></v-select>
                  </v-col>
                  <v-col cols="12" v-if="isnewparameter">
                    <v-alert text="Se agrego un nuevo periodo de tiempo al sistema." type="success"></v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-col cols="6" class="text-left">
                  <v-btn @click="addPeriodTime" class="btnsave"> Agregar </v-btn>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn @click="
                    openAddPeriods = !openAddPeriods;
                  isnewparameter = false;
                  " class="btndisable">
                    Continuar
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>

        </div>
        <v-row class="mt-6 mb-3" :class="this.data ? 'justify-space-between' : 'justify-end'">
          <v-col>
            <v-btn color="primary" @click="dialogBarCode = true">Gestión de códigos alternos</v-btn>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn class="ml-2" color="error" dark v-if="this.data && $store.getters['rolesUser/getCustomRole'](49)" @click.prevent.stop="confirmDelete()">
              Eliminar
            </v-btn>
            <v-btn class="ml-2" color="save" dark v-if="this.data && $store.getters['rolesUser/getCustomRole'](48)" @click.prevent.stop="updateService">
              Actualizar
            </v-btn>
            <v-btn class="ml-2" color="save" dark v-else-if="!this.data && $store.getters['rolesUser/getCustomRole'](47)" @click.prevent.stop="addService">
              Agregar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <!-- Add barcode -->
      <v-dialog class="barcode-dialog" v-model="dialogBarCode" max-width="50%">
        <v-card>
          <v-card-title>
            <h2>Asignación de código alterno</h2>
          </v-card-title>
          <v-card-text>
            <!-- Existing service -->
            <template v-if="this.data">
              <v-row class="mt-3">
                <v-col cols="12" class="d-flex justify-center">
                  <v-text-field outlined dense v-model="barCodeToAdd" label="Código alterno"
                    :rules="[(v) => !!v || 'El código alterno requerido']" required
                    @keyup.enter="addBarCodeData"></v-text-field>
                  <v-btn class="btncode ml-5" @click="addBarCodeData">Agregar</v-btn>
                </v-col>
              </v-row>
              <GeneralFilter :hideLimitLabel="true" :filterEndpoint="$API.inventoryitemsbarcodes.getAllBarCodesFiltered"
                :search="search" @emptyFilter="getAllBarCodesHistory" @filtered="filterHandlerBarCodes">
                <DxDataGrid class="isScrolledByGeneralFilter grid-max-width" :show-borders="true" :data-source="barCodes">
                  <DxPaging :page-size="5" />
                  <DxColumn :width="70" cell-template="edit-barcode"></DxColumn>
                  <DxColumn data-field="BarCode" caption="Código alterno"></DxColumn>
                  <DxColumn :width="70" cell-template="delete-barcode"> </DxColumn>
                  <template #edit-barcode="{ data }">
                    <div>
                      <a class="mx-1" @click="editShowBarCode(data.data)">
                        <v-icon color="primary">mdi-eye</v-icon>
                      </a>
                    </div>
                  </template>
                  <template #delete-barcode="{ data }">
                    <div>
                      <a class="mx-1" @click="deleteBarCodeData(data.data)">
                        <v-icon color="error">mdi-delete</v-icon>
                      </a>
                    </div>
                  </template>
                </DxDataGrid>
              </GeneralFilter>
            </template>
            <!-- existing Service -->
            <!-- new Service -->
            <template v-else>
              <v-row class="mt-3">
                <v-col cols="12" class="d-flex justify-center">
                  <v-text-field outlined dense v-model="barCodeToAdd" label="Código alterno"
                    :rules="[(v) => !!v || 'El código alterno requerido']" required
                    @keyup.enter="addBarCode"></v-text-field>
                  <v-btn class="btncode ml-5" @click="addBarCode">Agregar</v-btn>
                </v-col>
              </v-row>
              <GeneralFilter :hideLimitLabel="true" :filterEndpoint="filterPromiseBarCodes" :search="search"
                @emptyFilter="clearFilterBarCode" @filtered="filterHandlerBarCodes">
                <DxDataGrid class="isScrolledByGeneralFilter grid-max-width" :show-borders="true" :data-source="isFilteredBarCode ? barCodesToSaveFiltered : barCodesToSave
                  ">
                  <DxPaging :page-size="5" />
                  <DxColumn :width="70" cell-template="edit-barcode"></DxColumn>
                  <DxColumn data-field="BarCode" caption="Código alterno"></DxColumn>
                  <DxColumn :width="70" cell-template="delete-barcode"> </DxColumn>
                  <template #edit-barcode="{ data }">
                    <div>
                      <a class="mx-1" @click="editShowBarCode(data.data)">
                        <v-icon color="primary">mdi-eye</v-icon>
                      </a>
                    </div>
                  </template>
                  <template #delete-barcode="{ data }">
                    <div>
                      <a class="mx-1" @click="deleteBarCode(data.data)">
                        <v-icon color="error">mdi-delete</v-icon>
                      </a>
                    </div>
                  </template>
                </DxDataGrid>
              </GeneralFilter>
            </template>
            <!-- new Service -->
          </v-card-text>
          <v-card-actions>
            <v-col class="text-right">
              <v-btn right class="btncontinue" @click="dialogBarCode = false">Continuar</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Add barcode -->

      <!-- Edit barcode -->
      <v-dialog class="barcode-edit-dialog" v-model="dialogEditBarCode" max-width="40%">
        <v-card>
          <v-card-title>
            <h2>Editar de código alterno - {{ previousCode }}</h2>
          </v-card-title>
          <v-card-text>
            <!-- <h4>Seleccione los proveedores asociados a este producto.</h4> -->
            <v-row class="mt-3">
              <v-col cols="12" class="d-flex justify-center">
                <v-text-field @keyup.enter="handleEditBarCode" outlined dense v-model="barCodeToEdit" label="Código alterno*"
                  :rules="[(v) => !!v || 'El código alterno requerido']" required></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-col cols="12" class="d-flex justify-space-between">
              <v-btn class="primary ml-5" @click="handleEditBarCode">Editar</v-btn>
              <v-btn right class="btncontinue" @click="dialogEditBarCode = false">Continuar</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Edit barcode -->
      <v-dialog v-model="dialog" max-width="60%" height="100%">
        <ManageCategory :win="win" v-on:reloadCategories="reloadCategories" />
      </v-dialog>
    </div>
    <alerts class="alert-deep" v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert"
      :properties="alert">
    </alerts>
  </v-card>
</template>

<script>
import Alerts from "@/components/Alerts.vue";
import ManageCategory from "@/components/Categories/ManageCategory.vue";
import { mapState } from "vuex";
import GeneralFilter from "@/components/GeneralFilter";
import Block from "@/components/Block";
import { multipleCriteriaFilter } from "@/helpers/object";
import { DxDataGrid, DxColumn, DxPaging } from "devextreme-vue/data-grid";

export default {
  name: "serviceDetails",

  props: ["data"],

  components: {
    Alerts,
    GeneralFilter,
    DxDataGrid,
    DxColumn,
    DxPaging,
    Block,
    ManageCategory
  },

  data() {
    return {
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      valid: null,
      showModal: false,
      form: {},
      isVaccine: false,
      isMedicine: false,
      isHospital: false,
      isHost: false,
      typeItem: ["Canino", "Felino", "Todas las especies"],
      periodTime: [],
      openAddPeriods: false,
      periodform: {},
      isnewparameter: false,
      typebusiness: JSON.parse(localStorage.getItem("user")).businesstype || "",
      isIva: false,
      prevPrice: 0,
      families: [],
      categories: [],
      subcategories: [],
      loading: false,
      dialog: false,
      dialogBarCode: false,
      dialogEditBarCode: false,
      isFilteredBarCode: false,
      tabsCategories: "families",
      barCodesToSave: [],
      barCodesToSaveFiltered: [],
      barCodeToAdd: null,
      barCodeToEdit: null,
      previousCode: null,
      search: {
        filter: null,
      },
      barCodes: [],
    };
  },

  created() {
    this.$store.dispatch("getParam", { type: "serviceVacMed" }).then((data) => {
      this.periodTime = data.map((param) => {
        let periodTime = param.ParameterName + " " + param.ParameterValue;
        return { time: periodTime };
      });
    });
  },

  mounted() {
    console.log("la info");
    this.getFamilies();
    this.getCategories();
    this.getSubs();
    this.$store.dispatch("rolesUser/getAllRoles");
    this.typebusiness = JSON.parse(localStorage.getItem("user")).businesstype || "";

    if (this.data) {
      if (this.data.UnitPeriod == "Day") this.data.UnitPeriod = "Día";

      if (this.data.UnitPeriod == "Week") this.data.UnitPeriod = "Semana";

      if (this.data.UnitPeriod == "Month") this.data.UnitPeriod = "Mes";

      if (this.data.UnitPeriod == "Year") this.data.UnitPeriod = "Año";

      let timeConcat = this.data.PeriodTime + " " + this.data.UnitPeriod;
      this.form = {
        ID_Service: this.data.ID_Service,
        NameService: this.data.NameService,
        Name: this.data.Name,
        TypeService: this.data.TypeService,
        Price: this.data.Price,
        ID_Business: this.data.ID_Business,
        ID_Branch: this.data.ID_Branch,
        ID_User_Insert: this.data.ID_User_Insert,
        ID_User_Update: JSON.parse(localStorage.getItem("user")).id || "",
        PeriodTime: this.data.PeriodTime,
        UnitPeriod: this.data.UnitPeriod,
        customTime: timeConcat,
        family: this.data.family,
        category: this.data.category,
        subcategory: this.data.subcategory,
        ID_TypeService: this.data.ID_TypeService,
        //Iva: this.data.Iva,
      };
      if (this.form.TypeService == "VAC") {
        this.isVaccine = true;
      }

      if (this.form.TypeService == "MED") {
        this.isMedicine = true;
      }
      if (this.form.TypeService == "HOSP") {
        this.isHospital = true;
      }
      if (this.form.TypeService == "HOST") {
        this.isHost = true;
      }
    } else {
      this.form.ID_Business = JSON.parse(localStorage.getItem("user")).businessid || "";
      this.form.ID_Branch = JSON.parse(localStorage.getItem("user")).branch || "";
      this.form.ID_User_Insert = JSON.parse(localStorage.getItem("user")).id || "";
     
      this.form.TypeService = "OTR";
    }
    this.form.species = this.data ? this.data.species : "Todas las especies";

    this.getAllBarCodesHistory();
  },

  computed: {
    DefaultTime() {
      let periodTime = 0;
      if (this.form.UnitPeriod == "Day") {
        periodTime = "10";
        return periodTime;
      } else {
        return periodTime;
      }
    },
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    filteredCategories() {
      console.log("selected fam", this.form.family);
      return this.form.family
        ? this.categories.filter((category) => category.id_family == this.form.family)
        : [];
    },
    filteredSubcategories() {
      console.log("selected cat", this.form.category);
      return this.form.category
        ? this.subcategories.filter(
          (subcategory) => subcategory.id_cat == this.form.category
        )
        : [];
    },
  },

  watch: {
    isVaccine: {
      handler: function () {
        if (this.isVaccine) {
          this.isMedicine = false;
          this.isHospital = false;
          this.isHost = false;
        }
      },
      immediate: true,
    },
    isMedicine: {
      handler: function () {
        if (this.isMedicine) {
          this.isVaccine = false;
          this.isHospital = false;
          this.isHost = false;
        }
      },
      immediate: true,
    },
    isHospital: {
      handler: function () {
        if (this.isHospital) {
          this.isVaccine = false;
          this.isMedicine = false;
          this.isHost = false;
        }
      },
      immediate: true,
    },
    isHost: {
      handler: function () {
        if (this.isHost) {
          this.isVaccine = false;
          this.isMedicine = false;
          this.isHospital = false;
        }
      },
      immediate: true,
    },

    isIva: {
      handler: function (newVal) {
        if (newVal) {
          if (this.isIva) {
            let price = parseFloat(this.form.Price);
            this.form.Price = parseFloat(price * 1.13).toFixed(4);
          }
        } else {
          this.form.Price = this.form.Price / 1.13;
        }
      },
    },
  },

  methods: {
    reloadCategories() {
      this.getFamilies();
      this.getCategories();
      this.getSubs();
    },
    getFamilies() {
      this.$API.itemtypes.getFamiliesHistory().then((response) => {
        console.log(response);
        this.families = response;
      });
    },
    getCategories() {
      this.$API.itemtypes.getCategoriesHistory().then((response) => {
        this.categories = response;
      });
    },
    getSubs() {
      this.$API.itemtypes.getSubsHistory().then((response) => {
        this.subcategories = response;
      });
    },
    closeAlert() {
      this.alert.show = false;
      this.$emit("close");
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deleteservice") {
        this.deleteService();
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    confirmDelete() {
      this.showAlert(
        "question",
        "¿Estás seguro de eliminar este servicio?",
        "Esta acción no se puede deshacer",
        "deleteservice"
      );
    },
    addService() {
      if (this.isVaccine) {
        this.form.TypeService = "VAC";
        let arreglo = this.form.customTime.split(" ");
        this.form.PeriodTime = arreglo[0];
        this.form.UnitPeriod = arreglo[1];

        if (this.form.UnitPeriod == "Día") this.form.UnitPeriod = "Day";

        if (this.form.UnitPeriod == "Semana") this.form.UnitPeriod = "Week";

        if (this.form.UnitPeriod == "Mes") this.form.UnitPeriod = "Month";

        if (this.form.UnitPeriod == "Año") this.form.UnitPeriod = "Year";
      } else if (this.isMedicine) {
        this.form.TypeService = "MED";

        let arreglo = this.form.customTime.split(" ");
        this.form.PeriodTime = arreglo[0];
        this.form.UnitPeriod = arreglo[1];
        if (this.form.UnitPeriod == "Día") this.form.UnitPeriod = "Day";

        if (this.form.UnitPeriod == "Semana") this.form.UnitPeriod = "Week";

        if (this.form.UnitPeriod == "Mes") this.form.UnitPeriod = "Month";

        if (this.form.UnitPeriod == "Año") this.form.UnitPeriod = "Year";
      } else {
        this.form.species = null;
      }

      if (this.barCodesToSave) {
        this.form.barCodes = this.barCodesToSave;
      }

      if(this.isHospital) {
        this.form.TypeService = "HOSP";
        this.form.isTheHospitalService = 1;
      }
      if(this.isHost) {
        this.form.TypeService = "HOST";
        this.form.isTheHostService = 1;
      }

      this.$API.services
        .addService(this.form)
        .then(() => {
          this.showAlert(
            "success",
            "Servicio agregado",
            "Servicio agregado correctamente"
          );
        })
        .catch((err) => {
          this.showAlert("danger", "Error", err.message);
        });
    },
    deleteService() {
      this.$API.services
        .logicalDeleteService(this.data.ID_Service)
        .then((response) => {
          console.log(response);
          this.$emit("close", { isDeleted: true });
          this.showAlert("success", "Servicio eliminado", response.message);
        })
        .catch((err) => {
          this.showAlert("danger", "Error", err.message);
        });
    },
    updateService() {
      if (this.isVaccine) {
        this.form.TypeService = "VAC";

        let arreglo = this.form.customTime.split(" ");
        this.form.PeriodTime = arreglo[0];
        this.form.UnitPeriod = arreglo[1];

        if (this.form.UnitPeriod == "Día") this.form.UnitPeriod = "Day";

        if (this.form.UnitPeriod == "Semana") this.form.UnitPeriod = "Week";

        if (this.form.UnitPeriod == "Mes") this.form.UnitPeriod = "Month";

        if (this.form.UnitPeriod == "Año") this.form.UnitPeriod = "Year";
      } 
      else if (this.isMedicine) {
        this.form.TypeService = "MED";

        let arreglo = this.form.customTime.split(" ");
        this.form.PeriodTime = arreglo[0];
        this.form.UnitPeriod = arreglo[1];

        if (this.form.UnitPeriod == "Día") this.form.UnitPeriod = "Day";

        if (this.form.UnitPeriod == "Semana") this.form.UnitPeriod = "Week";

        if (this.form.UnitPeriod == "Mes") this.form.UnitPeriod = "Month";

        if (this.form.UnitPeriod == "Año") this.form.UnitPeriod = "Year";
      } 
      else if(this.isHospital) {
        this.form.TypeService = "HOSP";
        this.form.isTheHospitalService = 1;
        this.form.isTheHostService = 0;
      }
      else if(this.isHost) {
        this.form.TypeService = "HOST";
        this.form.isTheHostService = 1;
        this.form.isTheHospitalService = 0;
      }
      else {
        if (this.form.TypeService != "PLT") this.form.TypeService = "OTR";
        this.form.species = null;
      }

      if(!this.isHospital) {
        this.form.isTheHospitalService = 0;
      }
      if(!this.isHost) {
        this.form.isTheHostService = 0;
      }

      console.log(this.form);
      this.$API.services
        .updateService(this.data.ID_Service, this.form)
        .then((response) => {
          //this.$emit('close', {isDeleted: true});
          this.showAlert("success", "Servicio actualizado", response.message);
        })
        .catch((err) => {
          this.showAlert("danger", "Error", err.message);
        });
    },
    addPeriodTime() {
      this.periodform.ParameterType = "serviceVacMed";
      this.periodform.ParameterDescription = null;

      this.$API.general
        .addSystemParam(this.periodform)
        .then(() => {
          this.$store.dispatch("getParam", { type: "serviceVacMed" }).then((data) => {
            this.periodTime = data.map((param) => {
              let periodTime = param.ParameterName + " " + param.ParameterValue;
              return { time: periodTime };
            });
            this.openAddPeriods = !this.openAddPeriods;
          });
        })
        .catch((err) => {
          this.showAlert("danger", "Error", err.message);
        });
    },
    filterHandlerBarCodes(response) {
      this.isFilteredBarCode = true;
      this.barCodesToSaveFiltered = response;
    },
    filterPromiseBarCodes(filterObj) {
      const filterArray = ["BarCode"];
      return new Promise((resolve, reject) => {
        try {
          resolve(multipleCriteriaFilter(filterArray, filterObj, this.barCodesToSave));
        } catch (error) {
          reject(error);
        }
      });
    },
    checkDuplicate(NewBarCode) {
      this.loading = true;

      const localDuplicate = !(this.barCodesToSave.find(({ BarCode }) => NewBarCode === BarCode));

      if (localDuplicate) {
        return new Promise((resolve, reject) => {
          this.$API.inventoryitemsbarcodes.checkBarCodeExists({ barcode: NewBarCode })
            .then((response) => {
              console.log("estado", !response);
              resolve(!response);
            })
            .catch((err) => {
              console.error(err);
              reject(err);
            })
            .finally(() => {
              this.loading = false;
            });
        });
      }

      this.loading = false;
      return Promise.resolve(localDuplicate);
    },
    addBarCode() {
      this.checkDuplicate(this.barCodeToAdd)
        .then((isDuplicate) => {
          if (isDuplicate) {
            const newBarCode = {
              BarCode: this.barCodeToAdd,
              ID_Branch: this.form.ID_Branch,
              ID_Business: this.form.ID_Business,
            };
            this.barCodesToSave.push(newBarCode);
          } else {
            this.showAlert("warning", "Advertencia", "No debe repetir códigos alterno");
            this.dialogBarCode = false;
            this.barCodeToAdd = null;
          }
        })
        .catch((error) => {
          console.error("Error checking duplicate:", error);
        })
        .finally(() => {
          this.barCodeToAdd = null;
        });
    },
    addBarCodeData() {
      console.log(this.barCodeToAdd);
      console.log("soy este");
      this.loadingBarCode = true;
      if (this.checkDuplicate(this.barCodeToAdd)) {
        const newBarCode = {
          BarCode: this.barCodeToAdd,
          ID_Branch: this.form.ID_Branch,
          ID_Business: this.form.ID_Business,
          ID_Item: this.data.CodeService,
        };

        this.$API.inventoryitemsbarcodes
          .saveBarCode(newBarCode)
          .then((response) => {
            console.log(response)
            if (response.status == "400") {
              this.showAlert("warning", "Advertencia", response.message);
              this.dialogBarCode = false;
            }
            this.getAllBarCodesHistory();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loadingBarCode = false;
            this.barCodeToAdd = null;
          });
      } else {
        this.dialogBarCode = false;
        this.loadingBarCode = false;
        this.barCodeToAdd = null;
        this.showAlert("warning", "Advertencia", "No debe repetir códigos alterno");
      }
    },
    editShowBarCode(data) {
      this.dialogEditBarCode = true;
      this.previousCode = data.BarCode;
      this.barCodeToEdit = this.previousCode;
    },
    editBarCode() {
      this.checkDuplicate(this.barCodeToEdit)
        .then((isDuplicate) => {
          if (isDuplicate) {
            console.log(this.barCodesToSave)
            this.barCodesToSave.find((item) => {
              if (item.BarCode === this.previousCode) {
                item.BarCode = this.barCodeToEdit;
              }
            });
          } else {
            this.dialogBarCode = false;
            this.barCodeToAdd = null;
            this.dialogEditBarCode = false;
            this.showAlert("warning", "Advertencia", "No debe repetir códigos de barra");
          }
          this.barCodeToAdd = null;
          this.dialogEditBarCode = false;
        })
        .catch((error) => {
          console.error("Error checking duplicate:", error);
        });
    },
    editBarCodeData() {
      this.loading = true;
      if (this.checkDuplicate(this.barCodeToEdit)) {
        const editBarCode = this.barCodes.find(
          (item) => item.BarCode === this.previousCode
        );
        editBarCode.BarCode = this.barCodeToEdit;

        this.$API.inventoryitemsbarcodes
          .updateBarCode(editBarCode)
          .then((response) => {
            if (response.status == "400" || response.status == "404") {
              this.showAlert("warning", "Advertencia", response.message);
              this.dialogBarCode = false;
              this.dialogEditBarCode = false;
            }
            this.getAllBarCodesHistory()
          })
          .catch((error) => console.log(error));
      } else {
        this.showAlert("warning", "Advertencia", "No debe repetir códigos de barra");
      }
      this.dialogBarCode = false;
      this.dialogEditBarCode = false;
      this.loading = false;
    },
    handleEditBarCode() {
      if (this.data) {
        this.editBarCodeData()
      } else {
        this.editBarCode()
      }
    },
    deleteBarCode(data) {
      const index = this.barCodesToSave.indexOf(data);
      if (index > -1) {
        this.barCodesToSave.splice(index, 1);
      }
      this.dialogEditBarCode = false;
    },
    deleteBarCodeData(data) {
      console.log(this.data);
      console.log("aqui1", data);
      this.$API.inventoryitemsbarcodes
        .deleteBarCode(data)
        .then(() => {
          const index = this.barCodes.indexOf(data);
          if (index > -1) {
            this.barCodes.splice(index, 1);
          }
          this.dialogEditBarCode = false;
        })
        .catch((error) => console.log(error));
    },
    clearFilterBarCode() {
      this.isFilteredBarCode = false;
    },
    getAllBarCodesHistory() {
      this.$API.inventoryitemsbarcodes
        .getAllBarCodesHistory(this.data.CodeService)
        .then((data) => {
          this.barCodes = data;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style scoped>

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btncode {
  background-color: #3498DB !important;
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

.btncontinue {
  background-color: rgba(80, 80, 80, 1) !important;
  color: white;
}

.alert-deep {
  z-index: 20000 !important;
}

.barcode-dialog {
  z-index: 100 !important;
}

.barcode-edit-dialog {
  z-index: 150 !important;
}

.loading-deep {
  z-index: 9999999 !important;
}

.background-card{
  background-color: white;
}
</style>
