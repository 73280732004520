import axios from './axios'
export default {
  /**
   * Get products price list
   */
  priceList(params) {
    const options = {
      method: 'GET',
      url: 'manageproduct/pricelist',
      headers: { 'content-type': 'application/json' },
      params: params,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },

  priceListNoData() {
    const options = {
      method: 'GET',
      url: 'manageproduct/pricelistnodata',
      headers: { 'content-type': 'application/json' }
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },

  /**
   * Get products price list history
   */
  priceListHistory(params) {
    const options = {
      method: 'GET',
      url: 'manageproduct/pricelist/list/history',
      headers: { 'content-type': 'application/json' },
      params: params,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },

  /**
 * Get products price list filtered
 */
  priceListFiltered(params) {
    const options = {
      method: 'GET',
      url: 'manageproduct/pricelist/list/filter',
      headers: { 'content-type': 'application/json' },
      params: params,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },

  /**
   * Get All Products
   * @api {Get} manageproduct
   * @param {object} params
   */
  getProductsList(params) {
    const options = {
      method: 'GET',
      url: 'manageproduct',
      headers: { 'content-type': 'application/json' },
      params: params,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },

  getProductsListHistory(params) {
    const options = {
      method: 'GET',
      url: 'manageproduct/products/history',
      headers: { 'content-type': 'application/json' },
      params: params,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },

  getProductsListFiltered(params) {
    const options = {
      method: 'GET',
      url: 'manageproduct/products/pricelist/filtered',
      headers: { 'content-type': 'application/json' },
      params: params,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },

  getServicesListHistory(params) {
    const options = {
      method: 'GET',
      url: 'manageproduct/services/history',
      headers: { 'content-type': 'application/json' },
      params: params,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },

  getServicesListFiltered(params) {
    const options = {
      method: 'GET',
      url: 'manageproduct/services/pricelist/filtered',
      headers: { 'content-type': 'application/json' },
      params: params,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },

  /**
   * Add new price list
   * @api {POST} manageproduct/pricelist
   */
  addPriceList(data) {
    const options = {
      method: 'POST',
      url: 'manageproduct/pricelist',
      headers: { 'content-type': 'application/json' },
      data: data,
    }

    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },

  importPriceList(data) {
    const options = {
      method: 'POST',
      url: 'manageproduct/importPriceList',
      headers: { 'content-type': 'application/json' },
      data: data,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },
  importUpdatingPriceList(data) {
    const options = {
      method: 'POST',
      url: 'manageproduct/importUpdatingPriceList',
      headers: { 'content-type': 'application/json' },
      data: data,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },
  /**
   * Get Items From Old Price List
   * @api {GET}/manageproduct/pricelist/:id
   */
  itemsFromList(data) {
    const options = {
      method: 'GET',
      url: `/manageproduct/pricelistdetails/`,
      headers: { 'content-type': 'application/json' },
      params: data,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },
  itemsFromListFiltered(data) {
    const options = {
      method: 'GET',
      url: `/manageproduct/pricelistdetails/filtered`,
      headers: { 'content-type': 'application/json' },
      params: data,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },
  changuePriclistSelected(data) {
    const options = {
      method: 'POST',
      url: `/manageproduct/pricelist/changueselection`,
      headers: { 'content-type': 'application/json' },
      data: data,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },
  linkPriceList(data) {
    const options = {
      method: 'POST',
      url: `/manageproduct/pricelist/linkpricelist`,
      headers: { 'content-type': 'application/json' },
      data: data,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },

  getTieredDiscountByProduct(data) {
    const options = {
      method: 'POST',
      url: `/manageproduct/pricelist/getTieredDiscountByProduct`,
      headers: { 'content-type': 'application/json' },
      data: data,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  },

  deletePriceList(data) {
    const options = {
      method: 'POST',
      url: `/manageproduct/pricelist/deletePriceList`,
      headers: { 'content-type': 'application/json' },
      data: data,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    }) 
  },

  getInfoPriceByProduct(data) {
    const options = {
      method: 'POST',
      url: `/manageproduct/pricelist/getInfoPriceByProduct`,
      headers: { 'content-type': 'application/json' },
      data: data,
    }
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.message)
        })
    }) 
  }
}
