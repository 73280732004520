<template>
    <div style="z-index: 200; padding: 14px; min-height: 500px;">
        <block v-if="loading"></block>
        <v-stepper v-model="step" class="white pa-4">
            <v-stepper-header style="flex-wrap: nowrap;">
                <v-stepper-step :complete="step > 1" step="1">
                    Carga de datos
                </v-stepper-step>
                <v-stepper-step :complete="step > 2" step="2">
                    Previsualización de los datos
                </v-stepper-step>
                <v-stepper-step :complete="step > 3" step="3">
                    Procesamiento de los datos
                </v-stepper-step>
            </v-stepper-header>
            
            <!-- <v-row>
                <v-col>
                    <v-btn class="btnclean" @click="resetAll" :disabled="loading">
                        <v-icon class="mr-2">mdi-broom</v-icon>Reiniciar
                    </v-btn>
                </v-col>
            </v-row> -->
            <div style="margin: 1rem 0; display: flex; flex-direction: row; justify-content: space-between;">
                <v-btn class="btnsave" @click="downloadTemplate" :disabled="loading">
                    <v-icon class="mr-2">mdi-download</v-icon>Descargar plantilla para importar
                </v-btn>
                <v-btn class="btnclean" @click="resetAll" :disabled="loading">
                    <v-icon class="mr-2">mdi-broom</v-icon>Reiniciar
                </v-btn>
            </div>
            
            <v-stepper-items>
                <!-- Step 1: Upload Excel -->
                <v-stepper-content step="1">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-step">
                                <v-card-title class="font-weight-bold text-nowrap">Importar lista de precios desde archivo</v-card-title>
                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="12" md="6" style="margin-bottom: 1rem;">
                                            <v-btn class="btn-import mb-3" @click.prevent="showPriceLists = !showPriceLists">
                                                <v-icon class="mr-2">mdi-magnify</v-icon>Seleccionar lista de precios para editar
                                            </v-btn>
                                        </v-col>
                                        <v-col v-if="selectedPriceList" cols="12" md="6" style="margin-bottom: 1rem;">
                                            <h4>Lista seleccionada: {{ selectedPriceList.id }}</h4>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="12" class="col-input-file">
                                            <v-file-input
                                            style="min-width: 300px; width: 50%; display: flex; gap: 10px;"
                                            outlined
                                            dense
                                            prepend-icon="mdi-file-excel"
                                            v-model="selectedFile"
                                            accept=".xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            label="Seleccione hoja de calculo"
                                            :disabled="loading"
                                            >
                                            </v-file-input>
                                            <v-btn class="btn-import mb-3" @click="handleFileUpload" :disabled="loading || (selectedFile && uploadedFile)">
                                                <v-icon class="mr-2">mdi-import</v-icon>Cargar archivo
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field 
                                                v-model="priceListName"
                                                label="Nombre de la lista de precios"
                                                outlined 
                                                dense
                                                :disabled="updatePriceList"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-checkbox
                                                v-if="selectedPriceList"
                                                v-model="updatePriceList"
                                                label="Actualizar datos de la lista de precios seleccionada"
                                                outlined 
                                                dense
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                                <v-card-actions style="padding: 1rem; justify-content: flex-end;">
                                    <v-btn class="btncontinue" @click="nextStep" :disabled="loading">Continuar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                
                <!-- Step 2: Preview Data -->
                <v-stepper-content step="2">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-step">
                                <v-card-title class="font-weight-bold text-nowrap">Precios importados desde el archivo seleccionado para la lista: {{ priceListName }}</v-card-title>
                                <v-card-text v-if="selectedPriceList && updatePriceList" style="display: flex; flex-direction: row;">
                                    <!-- <v-icon color="red darken-4" style="margin: 10px; font-size: 20px;" >mdi-alert-circle</v-icon> -->
                                    <p style="margin-top: 20px; margin-bottom: 20px; font-size: 16px; color: #e3191e !important;">
                                        Se reemplazaran los datos de la siguiente lista: {{ selectedPriceList.id }}.
                                    </p>
                                </v-card-text>
                                <v-data-table 
                                    :headers="defaultHeaders" 
                                    :items="tableData"
                                    :items-per-page="50"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-page-first',
                                        lastIcon: 'mdi-page-last',                                            
                                        'items-per-page-options': [50, 100, 250],
                                        'items-per-page-text':'Precios por pagina:',
                                    }"
                                    class="data-table"
                                >
                                    <template v-slot:item.status="{ item }">
                                        <!-- <v-icon v-if="item.error && item.error.isDuplicated" color="yellow darken-4" style="margin: 10px; font-size: 20px;" @click.prevent="showError(item.error)">mdi-alert-circle</v-icon> -->
                                        <v-icon v-if="item.error" color="red darken-4" style="margin: 10px; font-size: 20px;" @click.prevent="showError(item.error)">mdi-alert-circle</v-icon>
                                        <v-icon v-else style="margin: 10px; font-size: 20px;">mdi-check-circle</v-icon>
                                    </template>
                                </v-data-table>
                                <v-card-actions style="padding: 1rem; justify-content: space-between;">
                                    <v-btn class="btncontinue" @click="prevStep">Anterior</v-btn>
                                    <v-btn class="btnoption" @click="onClickProcess">Procesar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>
        
                    <!-- Step 3: Process Data -->
                <v-stepper-content step="3">
                    <v-row>
                        <v-col cols="12">
                            <v-card v-if="loading" class="card-step" style="min-height: 400px;">
                                <v-card-title class="font-weight-bold text-nowrap">Procesando la información importada</v-card-title>
                            </v-card>
                            <v-card v-else class="card-step" style="min-height: 400px;">
                                <v-card-title class="font-weight-bold text-nowrap">Estado del procesamiento de datos</v-card-title>

                                <v-card-text v-if="!processError && pricesAllowed.length">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px;">
                                        Se han procesado un total de {{ pricesAllowed.length }} precios que fueron importados desde el archivo seleccionado.
                                    </h3>
                                    <v-card-actions style="padding: 0px; gap: 50px;">
                                        <v-btn class="btnoption" @click="showProcessed=!showProcessed" style="min-width: 100px;">
                                            {{!showProcessed ? 'Ver' : 'Ocultar'}}
                                            <v-icon v-if="!showProcessed" style="margin-left: 5px;">mdi-menu-down</v-icon>
                                            <v-icon v-else style="margin-left: 5px;">mdi-menu-up</v-icon>
                                        </v-btn>
                                        <v-btn class="btnoption" @click="exportToExcel('SUCCESS')" style="background-color: #00a178 !important;">
                                            <v-icon>mdi-file-excel</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card-text>
                                <v-data-table 
                                    v-if="showProcessed"
                                    :headers="defaultHeaders" 
                                    :items="pricesAllowed"
                                    :items-per-page="50"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-page-first',
                                        lastIcon: 'mdi-page-last',                                            
                                        'items-per-page-options': [50, 100, 250],
                                        'items-per-page-text':'Productos por pagina:',
                                    }"
                                    class="data-table"
                                >
                                </v-data-table>

                                <v-card-text v-if="!processError && pricesNotAllowed.length">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px; color: #e3191e !important;">
                                        No se logro procesar un total de {{ pricesNotAllowed.length }} precios cargados, debido a la existencia de errores en la información.
                                    </h3>
                                    <v-card-actions style="padding: 0px; gap: 50px;">
                                        <v-btn class="btnoption" @click="showNotProcessed=!showNotProcessed" style="min-width: 100px;">
                                            {{!showNotProcessed ? 'Ver' : 'Ocultar'}}
                                            <v-icon v-if="!showNotProcessed" style="margin-left: 5px;">mdi-menu-down</v-icon>
                                            <v-icon v-else style="margin-left: 5px;">mdi-menu-up</v-icon>
                                        </v-btn>
                                        <v-btn class="btnoption" @click="exportToExcel('ERROR')" style="background-color: #00a178 !important;">
                                            <v-icon>mdi-file-excel</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card-text>
                                <v-data-table 
                                    v-if="showNotProcessed"
                                    :headers="defaultHeaders" 
                                    :items="pricesNotAllowed"
                                    :items-per-page="50"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-page-first',
                                        lastIcon: 'mdi-page-last',                                            
                                        'items-per-page-options': [50, 100, 250],
                                        'items-per-page-text':'Productos por pagina:',
                                    }"
                                    class="data-table"
                                >
                                </v-data-table>

                                <v-card-text v-if="processError" style="display: flex; flex-direction: row;">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px;">
                                        Ha ocurrido un error en el procesamiento, vuelva a intentarlo más tarde. 
                                    </h3> 
                                </v-card-text>

                                <v-card-text v-if="!pricesAllowed.length && !pricesNotAllowed.length && !processError" style="display: flex; flex-direction: row;">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px;">
                                        No existen precios pendiente de procesamiento. 
                                    </h3> 
                                </v-card-text>
                                
                                <v-card-actions style="padding: 1rem; justify-content: space-between;">
                                    <v-btn class="btncontinue" @click="prevStep" :disabled="pricesAllowed.length > 0 || pricesNotAllowed.length > 0">Anterior</v-btn>
                                    <v-btn class="btncontinue" @click="resetAll">Volver al inicio</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                    
            </v-stepper-items>
        </v-stepper>

        <v-dialog v-model="showPriceLists" width="80%">
            <v-card>
                <v-btn elevation="0" color="red" class="close-btn" @click.prevent="showPriceLists = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-title style=" margin-top: 0.3rem; margin-bottom: 0.3rem; display: flex; justify-content: space-between;">
                    <h2 class="mt-3">Listas de Precios</h2>
                </v-card-title>
                <v-card-text>
                    <GeneralFilter entity="lista" :filterEndpoint="$API.priceList.priceListFiltered" :search="search" @emptyFilter="getAllPriceList" @filtered="filterHandlerPriceList">
                        <DxDataGrid  
                        :data-source="existingPriceList" 
                        key-expr="id"
                        :show-row-lines="true" 
                        
                        :show-borders="true"
                        :scrolling="{ useNative: true }"    
                        :column-auto-width="true"
                        style="max-height: 60dvh; overflow-y: auto;"
                        > 
                            <DxPager :showPageSizeSelector="true" :allowedPageSizes="[5, 10, 15]" />
                            <DxPaging :page-size="10" />
                            <DxColumn cell-template="download-template" caption=""></DxColumn>
                            <DxColumn data-field="date" caption="Fecha de Creación"></DxColumn>
                            <DxColumn data-field="id" caption="Nombre de la Lista de Precios"></DxColumn>
                            <template #download-template="{ data }">
                                <a href="#" class="mx-1" @click.prevent.stop="exportToExcel('PRICELIST', data.data)">
                                    <v-icon color="primary">mdi-download</v-icon>
                                </a>
                            </template>
                        </DxDataGrid>
                    </GeneralFilter>
                </v-card-text>
            </v-card>
        </v-dialog>


        <alert
            v-model="alert.show"
            @action="alert.action"
            :type="alert.type"
            :title="alert.title"
        >
            {{ alert.message }}
            <p style="margin-top: 1.5rem; color: white !important;">
                {{ alert.message2 ? alert.message2 : null }}
            </p>
        </alert>
    </div>
</template>
  
<script>
import Block from "@/components/Block";
//import { mapGetters } from "vuex";
import { DxDataGrid, DxColumn, DxPaging, DxPager } from "devextreme-vue/data-grid";
//import { printToExcel } from "@/helpers/printToexcel";
import GeneralFilter from "@/components/GeneralFilter.vue";
import * as XLSX from "xlsx";


export default {
    name: "ManageCustomersImport",
    props: [],
    components: {
        Block,
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxPager,
        GeneralFilter,
    },
    data() {
        return {
            step: 1,
            loading: false,
            id_bussiness: null,
            bussiness: null,
            id_branch: null,
            id_user: null,
            
            defaultHeaders: [
                {
                    "text": "Estado",
                    "value": "status"
                },
                {
                    "text": "ID Producto",
                    "value": "ID Producto"
                },
                {
                    "text": "Codigo Alterno",
                    "value": "Codigo Alterno"
                },
                {
                    "text": "Precio original",
                    "value": "Precio original"
                },
                {
                    "text": "Porcentaje de descuento",
                    "value": "Porcentaje de descuento"
                },
                {
                    "text": "Monto de descuento",
                    "value": "Monto de descuento"
                },
                {
                    "text": "Nuevo precio",
                    "value": "Nuevo precio"
                },
                {
                    "text": "Rango inicial",
                    "value": "Rango inicial"
                },
                {
                    "text": "Rango final",
                    "value": "Rango final"
                },
            ],

            showPriceLists: false,
            selectedFile: null,
            uploadedFile: false,
            updatePriceList: false,
            selectedPriceList: null,
            tableHeaders: [],
            tableData: [],
            priceListName: "",

            pricesAllowed: [],
            pricesNotAllowed: [],

            showProcessed: false,
            showNotProcessed: false,

            loadError: false,
            productsError: null,
            processError: null,
            existingPriceList: [],
            search: {},

            alert: {
                show: false,
                type: "info",
                title: "",
                message: "",
                message2: "",
                action: (() => null),
            },  
        };
    },
    mounted() {
        this.id_bussiness = JSON.parse(localStorage.getItem("user")).businessid;
        this.business = JSON.parse(localStorage.getItem("user")).business;
        this.id_branch = JSON.parse(localStorage.getItem("user")).branch;
        this.id_user = JSON.parse(localStorage.getItem("user")).id;
        this.getAllPriceList();
    },
    watch: {
        tableData: {
            deep: true,
            inmediate: true,
            handler(newValue) {
                this.productsError = newValue.some((item) => item.error);
            }
        },
        updatePriceList: {
            inmediate: true,
            handler(val) {
                if(val) {
                    this.priceListName = this.selectedPriceList.id;
                }
                else {
                    this.priceListName = "";
                }
            }
        }
    },
    methods: {
        showAlert(type, title, message, action, message2) {
            type = type == null ? "info" : type;
            this.alert.type = type;
            this.alert.title = title;
            this.alert.message = message;
            this.alert.message2 = message2 ? message2 : null;
            this.alert.action = action ? action : (() => null);
            this.alert.show = true;
        },
        nextStep() {
            if(this.step === 1) {
                if(!this.uploadedFile) {
                    this.showAlert("warning", "Advertencia", "Debe seleccionar y cargar un archivo antes continuar.");
                    return;
                }
                if(!this.priceListName) {
                    this.showAlert("warning", "Advertencia", "Debe escribir el nombre de la lista de precios antes continuar.");
                    return;
                }
                if(!this.updatePriceList && this.existingPriceList.some(item => item.id == this.priceListName)) {
                    this.showAlert("warning", "Advertencia", "Existe una lista de precios con ese nombre, debera cambiarlo antes de continuar.");
                    return;
                }

                this.handleDataLoad();
                this.step++;
            }
            else {
                this.step++;
            }
        },
        prevStep() {
            this.step--;
        },
        async getAllPriceList() {
            this.$API.priceList.priceListHistory()
                .then((response) => {
                    this.existingPriceList = response;
                    //console.log(this.existingPriceList);
                    
            });
        },
        async handleFileUpload() {
            if (!this.selectedFile) {  
                this.showAlert("warning", "Advertencia", "Por favor seleccionar un archivo.");
                return;
            }
            try {
                this.loading = true;
                this.resetData();
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    //const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    this.tableHeaders = jsonData[0].map((header) => ({ text: header, value: header }));
                    this.tableData = jsonData.slice(1).map((row) => {
                        const rowData = {};
                        this.tableHeaders.forEach((header, index) => {
                            rowData[header.value] = row[index];
                        });
                        return rowData;
                    });
                    
                    this.tableHeaders.unshift({text: 'Estado', value: 'status'});
                };
                
                reader.readAsArrayBuffer(this.selectedFile);
                reader.onloadend = () =>  {
                    if(this.tableHeaders.length && this.tableData.length) {
                        this.loading = false;
                        this.uploadedFile = true;
                        this.showAlert("success", "Exito", "Las datos del archivo seleccionado fueron cargado correctamente.");
                    }
                    else {
                        //throw new Error();
                        this.loading = false;
                        this.uploadedFile = false;
                        this.showAlert("error", "Advertencia", "Ha ocurrido un error al cargar los datos, por favor revise el archivo y vuelva a intentarlo más tarde.");
                        this.resetAll();
                    }
                };
            } 
            catch (error) {
                console.log(error);
                this.loading = false;
                this.uploadedFile = false;
                this.showAlert("error", "Advertencia", "Ha ocurrido un error al cargar los datos, por favor revise el archivo y vuelva a intentarlo más tarde.");
                this.resetAll();
            }
        },
        async handleDataLoad() {            
            this.loading = true;
            try {
                //console.log(this.tableData);
                this.tableHeaders.forEach((item, index) => {
                    if(item.text !== this.defaultHeaders[index].text || item.value !== this.defaultHeaders[index].value) {
                        throw new Error('El formato del archivo cargado es incorrecto, por favor utilice plantilla disponible para descargar en el sistema.');
                    }
                });

                await this.checkProducts();

                this.loading = false;
            } 
            catch (error) {
                console.log(error);
                this.loading = false;
                this.showAlert("warning", "Advertencia", error.message || "Ha ocurrido un error al procesar el archivo, vuelva a intentarlo más tarde o contacte al soporte.");
                this.resetAll();
            }
        },
        async checkProducts() {
            try {
                const arrayID = this.tableData.map(item => {
                    if(item["ID Producto"] != null && item["ID Producto"] != undefined && item["ID Producto"] != '') return item["ID Producto"];
                });
                const arrayBarcodes = this.tableData.map(item => {
                    if(item["Codigo Alterno"] != null && item["Codigo Alterno"] != undefined && item["Codigo Alterno"] != '') return item["Codigo Alterno"];
                });          

                const existingProducts = await this.$API.products.getProductsByArrayID({ arrayID, arrayBarcodes });
                //console.log(existingProducts.data);

                for (const item of this.tableData) {
                    if(!existingProducts.data.some(el => el.ID_Item == item["ID Producto"] || (el.BarCode && el.BarCode == item["Codigo Alterno"]))) {
                        item.error = {
                            notExist: true
                        }
                    }

                    if(!item["ID Producto"] && existingProducts.data.some(el => el.BarCode && el.BarCode == item["Codigo Alterno"])) {
                        const element = existingProducts.data.find(el => el.BarCode && el.BarCode == item["Codigo Alterno"]);
                        item["ID Producto"] = element.ID_Item;
                    }
                }             
            } 
            catch (error) {
                console.log(error);
                this.showAlert("warning", "Advertencia", "Ha ocurrido un error al validar los datos, vuelva a intentarlo más tarde o contacte al soporte.");
                this.resetAll();
            }
        },
        async processData() {
            this.loading = true;
            this.alert.show = false;
            this.nextStep();
            try {
                if(!this.tableData.length) throw new Error('No ha seleccionado archivo con la información.');

                const priceListData = [];

                for (const item of this.tableData) {
                    const priceList = {
                        PriceListName: this.priceListName,
                        ID_Item: item["ID Producto"],
                        ItemOriginalPrice: parseFloat(item["Precio original"]),
                        ItemPriceDiscountPercent: parseFloat(item["Porcentaje de descuento"]),
                        ItemPriceDiscountAmount: parseFloat(item["Monto de descuento"]),
                        ItemNewPrice: parseFloat(item["Nuevo precio"]),
                        ItemCurrency: '$',
                        Active: 0,
                        InitialRange: item["Rango inicial"] ? parseInt(item["Rango inicial"]) : null,
                        FinalRange: item["Rango final"] ? parseInt(item["Rango final"]) : null,
                        ID_Branch: this.id_branch,
                        ID_Business: this.id_bussiness,
                        ID_User_Insert: this.id_user,
                        IsGlobalBranch: 0,
                    }

                    if(!item.error) {
                        priceListData.push(priceList);
                        this.pricesAllowed.push(item);
                    }
                    else {
                        this.pricesNotAllowed.push(item);
                    }
                }

                //console.log(priceListData);
                let result;
                if(this.updatePriceList) {
                    result = await this.$API.priceList.importUpdatingPriceList({data: priceListData, id: this.selectedPriceList.id});
                }
                else {
                    result = await this.$API.priceList.importPriceList(priceListData);
                }
                
                if(result.success) {
                    this.loading = false;
                    this.showAlert("success", "Exito", "Se procesaron los datos importados con exito.");
                }
                else {
                    throw new Error('Ha ocurrido un error al procesar el archivo, vuelva a intentarlo más tarde.');
                }
                
                
            } catch (error) {
                console.log(error);
                this.processError = true;
                this.loading = false;
                this.showAlert("warning", "Advertencia", error.message ? error.message : "Ha ocurrido un error al procesar el archivo, vuelva a intentarlo más tarde.");
            }
        },
        exportToExcel(value, pricelistData) {
            if(value === 'ERROR') {
                const headers = this.defaultHeaders.map(header => header.text);
                const data = this.pricesNotAllowed.map(item => this.defaultHeaders.map(header => item[header.value]));
    
                const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Plantilla lista de precios');
    
                XLSX.writeFile(workbook, 'lista_de_precios_errores.xlsx');
            } 
            
            if(value === 'SUCCESS') {
                const headers = this.defaultHeaders.map(header => header.text);
                const data = this.pricesAllowed.map(item => this.defaultHeaders.map(header => item[header.value]));
    
                const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Plantilla lista de precios');
    
                XLSX.writeFile(workbook, 'lista_de_precios_procesados.xlsx');
            } 
            
            if(value === 'PRICELIST') {
                const headers = this.defaultHeaders.map(header => header.text);
                headers.splice(0, 1);
                console.log(pricelistData.data);
                
                const data = pricelistData.data.map(item => [ item.ID_Item, '', item.ItemOriginalPrice, item.ItemPriceDiscountPercent, item.ItemPriceDiscountAmount, item.ItemNewPrice, item.InitialRange, item.FinalRange ]);
    
                const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, `Plantilla de precios`);
    
                XLSX.writeFile(workbook, `lista_${pricelistData.id}.xlsx`);

                this.showPriceLists = false;
                this.updatePriceList = true;
                this.selectedPriceList = pricelistData;

                this.showAlert("success", "Descarga exitosa", "Se ha descargado correctamente la lista seleccionada en formato hoja de calculo, no modificar los encabezados para evitar errores a la hora de procesar el archivo.");
            }
            
        },
        filterHandlerPriceList(response) {
            this.existingPriceList = response;
        },
        validateRangesItems() {
            const rangesArray = this.tableData.map(item => {
                return {
                    ID: item["ID Producto"],
                    initial: item["Rango inicial"],
                    final: item["Rango final"]
                }
            });
            
            const defaultRangesArray = [];
            rangesArray.forEach(item => {
                if(!item.initial && !item.final) {
                    defaultRangesArray.push(item.ID);
                }
            });
            
            const response = [];
            for (const item of rangesArray) {
                if(item.initial && item.final) {
                    const dafaultRange = defaultRangesArray.includes(item.ID);
                    const isValid = [...rangesArray].filter(el => el.ID === item.ID).every(el => (el.initial !== item.final) && (el.final !== item.initial));
                    response.push({ID: item.ID, dafaultRange: dafaultRange, validRange: isValid});
                }
            }

            return response;
        },
        onClickProcess() {
            const validateRanges = this.validateRangesItems();
            //console.log(validateRanges);
            if(!validateRanges.every(item => item.dafaultRange === true)) {
                this.showAlert("warning", "Advertencia", `Existen productos sin rango de precios por defecto, por favor complete la información antes de continuar.`);
                return;
            }
            if(!validateRanges.every(item => item.validRange === true)) {
                this.showAlert("warning", "Advertencia", `Existen productos con rangos de precios incorrectos, por favor verifique la información antes de continuar.`);
                return;
            }

            this.showAlert("confirm", "Atención", "¿Esta seguro que desea continuar?", this.processData);
        },
        showError(values) {
            if(values.notExist) {
                return this.showAlert("warning", "Advertencia", `El producto al cual quiere asignar el precio no se encuentra actualmente en los registros del sistema, el mismo no sera procesado.`);
            }
        },
        resetData() {
            this.tableHeaders = [];
            this.tableData = [];
            this.loadError = false;
            this.productsError = null;
            this.processError = null;
            this.uploadedFile = false;
        },
        resetAll() {
            this.step = 1;
            this.loading = false;
            this.uploadedFile = false;
            this.selectedFile = null;
            this.tableHeaders = [];
            this.tableData = [];
            this.pricesAllowed = [];
            this.pricesNotAllowed = [];
            this.showProcessed = false;
            this.showNotProcessed = false;
            this.loadError = false;
            this.productsError = null;
            this.processError = null;
            this.updatePriceList = false;
            this.selectedPriceList = null;
        },
        downloadTemplate() {
            const filePath = '/Plantilla_Lista_Precios.xlsx';
            const link = document.createElement('a');
            link.href = filePath;
            link.download = 'Plantilla_Lista_Precios.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
</script>

<style scoped>

.card-step {
    min-height: 400px; 
    width: 100%; 
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.col-input-file {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 1rem;
}

.btn-import {
  background-color: #3498db !important;
  color: white;
  min-width: 250px !important;
  height: 40px !important;
}

.btnoption {
  background-color: #3498DB !important;
  color: white;
}

.btncontinue {
  background-color: rgba(80, 80, 80, 1) !important;
  color: white;
}

.btndisable {
    background-color: #41464c !important;
    color: white;
}


</style>

<style>

.mdi-file-excel::before {
    font-size: 32px !important;
}

.data-table .v-data-table__wrapper thead th {
    height: 35px !important;
    text-wrap: nowrap;
    min-width: 100px;

}
.data-table .v-data-table__wrapper tbody tr {
    height: 35px !important;
    text-wrap: nowrap;
    min-width: 100px;
}
.data-table .v-data-table__wrapper tbody td {
    height: 35px !important;
    text-wrap: nowrap;
    min-width: 100px;
}

.tabs-seg .v-slide-group__content {
    justify-content: space-between;
}

</style>